.time-input-field {
  @apply ring-0 w-6 text-center outline-none bg-transparent;
}

.time-input-wrapper {
  @apply flex items-center h-full rtl:flex-row-reverse rtl:justify-end;
}

.time-input-separator {
  @apply mx-2;
}
