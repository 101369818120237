@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import "primereact/resources/themes/lara-light-cyan/theme.css";
@import "./components/index.css";
@import "./tailwind/index.css";
@import "./custom/custom.css";

:root {
    --white-color: #ffffff;
    --black-color: #333333;
    --border-color: #E0E0E0;

    /* Font settings */
    --font-family: 'Roboto', sans-serif !important;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 600;
    --font-weight-bold: 700;
    --font-size-14: 14px;

    --cinnabar: #EC4830;
    --trueBlue: #0078D4;
    --trueBlueDark: #0067b7;
    --trueBlueLight: #28a2ff;
    --arsenic: #424242;
    --mistyRose: #FFE2E0;
    --philippineSilver: #b3b3b3;
    --white: #ffffff;
    --blue: #0000FF;
    --americanOrange: #FC880E;
    --maize: #F5C145;
    --maizeCrayola: #FFC645;
    --chineseWhite: #E0E0E0;
    --linen: #FFEBEA;
    --antiFlashWhite: #F1F2F6;
    --eerieBlack: #1F1F1F;
    --quartz: #49454F;
    --graniteGray: #616161;
    --gainsboro:#D8DAE0;
    --ghostWhite:#F8F9FF;
    --maastrichtBlue: #001C38;
    --aliceBlue: #E8F0FF;
    --northTexasGreen: #009F25;
    --brightGreen: #E6F4EA;
    --sonicSilver: #707579;
    --sonicSilverGray: #79747E;
    --violetsAreBlue: #918CFF;
    --aliceGrayBlue: #EFEEFF;
    --pastelRed: #FD6363;
    --linenRed: #FFECEC;
    --latte: #FFF8E8;
    --aliceBluePurple: #F3F7FB;
    --silverSand: #B8BCCA;
    --honeydew: #ECFDF5;
    --gainsBoroLight: #DADCE0;
    --ghostWhiteLight: #F8FAFC;
    --platinum: #E2E6EA;
    --rajah: #fee6cc;
    --brightGray: #EAECEE;
    --charcoal: #374151;
    --cultured: #F5F5F5;
    --chinesesilver: #CCCCCC
    /* Notes:
     * For color names and hex codes, use https://www.color-name.com/hex/
     * Ensure colors are named and referenced appropriately based on their usage.
     * Any new colors should be added with their corresponding hex codes and names.
     */
}

.p-column-filter-menu-button-active {
    color: var(--blue);;
}

.dashboardalerttable {
    color: rgb(239 68 68 / 1);;
    background-color: rgb(254 242 242 / 1);
}

.p-button .p-component .p1 {
    color: var(--white);
    background-color: var(--cinnabar);
}


/* For webkit browsers */
div::-webkit-scrollbar {
    width: 4px;
}

div::-webkit-scrollbar-track {
    background: transparent;
}

div::-webkit-scrollbar-thumb {
    background-color: var(--philippineSilver);
    border-radius: 10px;
}

/* For Firefox */
div {
    scrollbar-width: thin;
    scrollbar-color: var(--philippineSilver) transparent;
}
