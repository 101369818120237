.p-dropdown{
    border: 1px solid var(--border-color);
    font-size: var(--font-size-14)
}

.p-inputtext, .p-dropdown-panel{
    font-size: var(--font-size-14)
}

.new-defect-image-header {
    border-radius: 8px;
    background: #FFF;
    width: 50px;
    max-width: 2000px;
    height: 40px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    margin-top:12px
}

.defect-image-header {
    border-radius: 8px;
    background: #FFF;
    width: 55%;
    max-width: 300px;
    height: 48px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    margin-top:12px
}

@media (max-width: 1024px) {
    .new-defect-image-header {
        width: max-content;
        height: auto;
    }
}

/* Apply custom defect-image-header for larger screens */
@media (max-width: 1024px) {
    .defect-image-header {
        width: max-content;
        height: auto;
    }
}

/* Added selected-border for review thumb images */
.selected-border {
    position: relative;
}

.selected-border::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background:transparent;
    border:2px solid #0078D4;
    border-radius:4px;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}


.no-focus-outline:focus {
    outline: none;
    box-shadow: none;
}

[data-pc-name="overlaypanel"]> button {
    position: absolute;
    top: 13px;
    right: 10px;
}

.icon-field {
    display: flex;
    align-items: center;
}

.icon-left .input-icon {
    margin-right: 8px;
}

.input-icon {
    display: flex;
    align-items: center;
}

/* ============================== */
/*  Styles for PDataTable */
/* ============================== */

.p-paginator-bottom{
    font-family: var(--font-family);
    padding: 0;
}

.p-paginator-pages{
    font-family: var(--font-family);
}


button.p-paginator-page.p-paginator-element.p-link.p-highlight {
    font-weight: 900;
}

.p-datatable .p-datatable-thead > tr > th {
    padding: 7px
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 10px !important;
}

/* Base styles for Alert view with striping */
.alert-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background-color: var(--linen); /* Light red background for odd rows in Alert view */
}

.alert-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-even {
    background-color: var(--white); /* White background for even rows in Alert view */
}

/* Hover effect for rows in Alert view */
.alert-datatable.p-datatable-striped .p-datatable-tbody > tr:hover {
    background-color: var(--mistyRose); /* Light red background on hover */
}
/* ============================== */
/* End of Styles for PDataTable */
/* ============================== */


/* ============================== */
/* Start of Styles for PAccordion */
/* ============================== */

/* Removes the outline from the accordion component */
.p-accordion{
    outline: none!important;
}

/* Custom styles for the accordion header link */
.accordion-header-custom > a{
    padding: 7px !important;
    margin: 0 !important;
    background-color: transparent;
    border: none;
    outline: none!important;
}

/* Custom styles for the accordion content */
.accordion-content-custom > .p-accordion-content {
    padding: 0 !important;
    margin: 0 !important;
    border: none;
    outline: none!important;
}

/* Customize the appearance of calendar input fields */
.custom-calendar > input {
    height: 38px !important; /* Set the height of the input field */
    border-radius: 0.375rem !important; /* Apply rounded corners to the input field */
    margin-right: 2px; /* Add right margin */
    margin-left: 10px; /* Add left margin */
}

/* Remove box shadow from focused calendar input fields */
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
    box-shadow: none!important; /* Remove the box shadow on focus */
}

/* Remove box shadow from focused multi-select fields */
.p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none!important; /* Remove the box shadow on focus */
}

/* Remove box shadow from focused enabled input text fields */
.p-inputtext:enabled:focus {
    box-shadow: none!important; /* Remove the box shadow on focus */
}

/* Customize padding for text inputs */
.custom-text {
    padding-right: 2px; /* Add right padding */
    padding-left: 10px; /* Add left padding */
}

/* Custom styles for the multi-select label and items label */
.custom-multiselect .p-multiselect-label,
.custom-multiselect .p-multiselect-items-label {
    width: auto;
    padding: 0;
    margin-right: 2px;
    margin-left: 10px;
    flex-grow: 0;
    outline: 0;

}

/* Custom styles for the multi-select trigger */
.custom-multiselect .p-multiselect-trigger {
    padding: 0;
    margin: 0 7px;
    display: flex;
    align-items: center;
    width: auto
}

/* Custom styles when multi-select is focused */
.custom-multiselect.p-inputwrapper-focus .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: initial;
    background-color: inherit;
}

/* ============================== */
/* End of Styles for PMultiSelect */
/* ============================== */

/* Define a custom class for the BasemapToggle widget */
.custom-basemap-toggle {
    width: 40px;  /* Adjust the width as needed */
    height: 40px; /* Adjust the height as needed */
    overflow: hidden; /* Ensure the content is clipped to fit within the size */
}

.custom-basemap-toggle .esri-widget--button {
    width: 100%;  /* Make sure the button fits the custom size */
    height: 100%; /* Make sure the button fits the custom size */
}


.rotate-image-header {
    border-radius: 8px;
    background: var(--white);
    width: 55%;
    max-width: max-content;
    height: 38px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    margin-top:12px;
    gap: 7px;
    z-index:1;
    padding: 0 5px;
}

/* Custom tooltip style */
.p-tooltip{
    font-size: 12px; /* Adjust the font size as needed */
}

.p-button:focus {
    box-shadow: none!important;
}

/* Custom Tooltip Styling */
.custom-tooltip .p-tooltip-text {
    font-size: 12px; /* Adjust font size */
    background-color: var(--white); /* Tooltip background color */
    color: var(--arsenic); /* Tooltip text color */
    padding: 4px;
    text-align: center;
}

/* Custom Tooltip Styling */
.custom-tooltip.active>div {
    font-size: 12px; /* Adjust font size */
    background-color: var(--trueBlue); /* Tooltip background color */
    color: var(--white); /* Tooltip text color */
}

.custom-tooltip .p-tooltip-arrow {
    border-top-color: var(--border-color) /* Tooltip arrow color */
}

/* Custom grid styles with media queries */
.custom-grid {
    grid-template-rows: 1fr 1fr; /* Default for small screens */
}

/* Apply custom grid-template-rows for larger screens */
@media (min-width: 1024px) {
    .custom-grid {
        grid-template-rows: calc(80vh - 80px - 2rem) 20vh;
    }
}


/* Apply custom grid-template-rows for larger screens */
@media (max-width: 1024px) {
    .custom-map>.esri-view {
        width: 100%!important;
        height: 200px!important;
    }
}


.custom-overlay-panel .p-overlaypanel-content {
    padding: 0; /* Varsayılan padding'i sıfırla */
}


.custom-overlay-panel .p-overlaypanel-close {
    top: 5px!important;
}

.p-column-filter.p-fluid.p-column-filter-menu {
    display: none;
}


/*
 * Styles for mini dropdown component used in the UI.
 * This class provides specific styling for dropdowns with a compact design.
 */
.mini-dropdown{
    /* Styles for the dropdown label (displayed text) */
    .p-dropdown-label{
        height: 32px;
        display: flex;
        align-items: center;
        padding: 0;
        padding-left: 8px;
        color: var(--eerieBlack);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.28px;
    }
    /* Styles for the dropdown trigger (arrow icon) */
    .p-dropdown-trigger{
        width: 30px;
    }
}

/* Change the background color of the checkbox when highlighted */
.p-checkbox.p-highlight .p-checkbox-box{
    background-color: var(--trueBlue);
}

/* Set the default border and border-radius for the checkbox */
.p-checkbox-box{
    border-radius: 2px;
    border: 1px solid var(--quartz)!important;
}

/* Remove border when the checkbox is highlighted */
.p-highlight .p-checkbox-box{
    border: none!important;
}

/* Customize the size of the checkbox */
.custom-checkbox .p-checkbox {
    width: 18px !important;
    height: 18px !important;
}

/* Customize the size of the checkbox box */
.custom-checkbox .p-checkbox-box {
    width: 18px !important;
    height: 18px !important;
    padding: 0 !important;
}

/* Remove margin from the checkbox input */
.custom-checkbox .p-checkbox-input {
    margin: 0 !important;
}

/** Custom style for the text inside the Chip component */
.custom-chip > .p-chip-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}


.esri-popup--shadow{
    box-shadow: none!important;
}

.esri-features__container{
    padding: 0!important;
}

 .esri-popup__main-container{
    border-radius: 8px!important;
}

.header-actions--end calcite-action[data-test="close"] {
    height: 25px;
    line-height: 25px; /* Center the icon/text vertically */
    padding: 0; /* Adjust padding if necessary */
}

.esri-view {
    outline: none!important;
    --esri-view-outline-color: transparent!important;
    --esri-view-outline: 0!important;
    --esri-view-outline-offset: 0!important;
}


@media (max-width: 660px) {
    .esri-features__content-feature{
        padding-left: 10px;
        padding-right: 10px;
    }
}

:focus-visible{
    outline: none!important;
}

.p-autocomplete-item{
    margin: 0 !important;
    padding: 0 !important;
}

/** Custom style for the MultiSelect filter input */
.custom-multiselect-filter .p-multiselect-filter-container > input {
    height: 32px!important;
    padding: 10px !important;
}
/* Styles for table headers within the default table */
.default-table .p-datatable-thead>tr>th {
    background-color: var(--aliceBluePurple);
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

/* Styles for the first table header in each row of the default table */
.default-table .p-datatable-thead>tr>th:first-child {
    padding-left: 1rem;
}

/* Styles for the content area of the file upload component */
.p-fileupload-content {
    border: 2px dashed #E2E6EA;
    height: 150px;
    background-color: #F8FAFC;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #242634
}

.custom-dropdown{
    outline: none;
    box-shadow: none;
}

.custom-dropdown:focus {
    outline: none;
    box-shadow: none;
}

.custom-dropdown-sm {
    height: 30px!important;
}

.custom-dropdown-sm > .p-dropdown-label.p-inputtext{
    display: flex;
    height: 30px;
}

.custom-dropdown-sm>.p-dropdown-trigger{
    padding-right: 14px !important;
    margin: 0 !important;
    width: auto !important;
}

.custom-speed-dial .p-speeddial-button{
    width: 30px;
    height: 30px;
}

.custom-speed-dial .p-speeddial-item>a{
    width: 35px;
    height: 35px;
}

.custom-speed-dial .p-speeddial-action {
    background-color: var(--cultured);
    color: black;
}

.custom-speed-dial .p-speeddial-action:hover{
    background-color: var(--chinesesilver);
}


.p-treenode-content{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-grow: 1
}

.p-treenode-label{
    flex-grow: 1;
}

.p-treenode-content:focus-visible{
    outline: none!important;
}


.p-tree-container > .p-treenode > .p-treenode-content > button  {
    display: none!important;
}

.p-tree-container > .p-treenode > .p-treenode-content  {
    border-bottom: 1px solid var(--gainsBoroLight);
    border-radius:0;
}

.p-treenode-children{
    border-left: 1px solid var(--gainsBoroLight);
    border-radius:0;
}

.p-treenode-children>.p-treenode> .p-treenode-content{
    border-bottom: 1px solid var(--gainsBoroLight);
    border-radius:0;
}

.custom-accordion{
    font-family: var(--font-family);
}

.custom-accordion > a {
    display: flex;
    flex-direction: row-reverse;
    background-color: #FFFFFF;
    padding-left: 6px;
    padding-right: 6px;
    color: var(--arsenic);
    font-size: 16px;
    font-weight: 400;
}

.custom-accordion.text-sm > a {
    display: flex;
    flex-direction: row-reverse;
    background-color: #FFFFFF;
    color: var(--arsenic);
    font-size: 14px;
    font-weight: 400;
    padding: 15px 6px
}

.custom-accordion.text-sm  .p-accordion-content{
    padding: 0
}

.custom-accordion > a > svg {
    color: var(--trueBlue);
}

.custom-accordion-main > div {
    border-bottom: 1px solid #E2E6EA;
}

.custom-accordion-main > div > div > .p-accordion-content {
    font-size: 14px;
    color: var(--arsenic);
    font-weight: 400;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 12px;
}

.custom-datatable .p-datatable-thead{
    font-family: var(--font-family);
    background-color: var(--aliceBluePurple)!important;
    height: 48px!important;
    margin-left: 15px
}

.custom-datatable .p-datatable-thead > tr > th {
    background-color: var(--aliceBluePurple)!important;
}

.custom-datatable .p-datatable-thead > tr > th > div > span {
    color: var(--arsenic);
    font-size: 14px;
    font-weight: 500;
}

.custom-datatable .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--chineseWhite);
    height: 62px!important;
}

.custom-datatable .p-datatable-tbody > tr > td {
    padding: 0!important;
    padding-left: 10px!important;
    padding-right: 10px!important;
    color: #424242;
    font-size: 14px;
}

.p-datatable-selectable .p-selectable-row, .p-datatable-selectable-cell .p-selectable-cell {
    cursor: auto;
}

.markdown-body pre {
    word-wrap: break-word;
    overflow: auto;
    max-width: 100%;
    box-sizing: border-box;
    background-color: #f8f9fa;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.markdown-body > h1, h2, h3, h4, h5, h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: var(--maastrichtBlue)!important;
}

.markdown-body ul {
    margin-left: 10px!important;
}

.markdown-body  a {
    font-style: italic;
    text-decoration: underline;
}

.markdown-body  ul > li {
    display: list-item;
    list-style: disc;
    list-style-position: inside;
}

.markdown-body p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--maastrichtBlue);
}

.esri-features__header, .header-container {
    display: none !important;
}

.esri-features__container:not(:empty){
    padding: 0 !important;
}

::part(esri-features__pagination-action-bar) {
    display: none !important;
}

.custom-recenter-button {
    position: absolute;
    bottom: 90px;
    right: 15px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    width: 32px;
    height: 32px;
}

.custom-recenter-button:hover {
    background-color: #f2f2f2; /* Slight hover effect */
}

.esri-feature__content-element{
    padding: 0 !important;
}

.markdown-body table {
    border-collapse: collapse;
    width: 100%;
    margin: 16px 0;
    font-size: 14px;
}

.markdown-body th, .markdown-body td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.markdown-body th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.inline-math {
    font-family: 'Arial', sans-serif;
    font-size: 1.2em;
    background-color: #f5f5f5;
    padding: 2px 5px;
    border-radius: 4px;
    display: inline-block;
    white-space: nowrap;
}

.block-math {
    font-family: 'Arial', sans-serif;
    font-size: 1.5em;
    background-color: #f5f5f5;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: center;
}


/* InputSwitch Custom CSS */
[data-pc-name='inputswitch'][data-p-highlight='true'] [data-pc-section='slider'] {
    background: var(--trueBlue) !important;
}

.custom-opacity-slider .p-slider-range {
    background: var(--trueBlue) !important; /* Yeşil ton */
}

.custom-opacity-slider .p-slider-handle {
    border: 4px solid var(--trueBlue) !important;
}

.custom-opacity-slider .p-slider-handle {
    background: var(--white) !important;
}