.checkbox {
  @apply h-5 
    w-5 
    border 
    border-gray-300 
    dark:border-gray-600
    rounded 
    cursor-pointer 
    inline-block 
    align-middle 
    flex-shrink-0 
    p-0 
    appearance-none
    transition-colors
    duration-150
    ease-in-out
    shadow-sm;

  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    @apply bg-current border-transparent;
  }

  &.disabled {
    @apply text-gray-200 bg-gray-100 dark:bg-gray-600 dark:text-gray-400 cursor-not-allowed;
  }
}

.checkbox-label {
  @apply inline-flex cursor-pointer mr-3;

  &.disabled {
    @apply cursor-not-allowed;
  }
}
